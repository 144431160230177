export function getStudentID(customerUID: string): string {
  return customerUID.substr(0, 3).toUpperCase();
}

export function getOnlineRoom(projectID: string): number {
  let val = 0;
  if (projectID.length === 0) {
    return val;
  }
  for (let i = 0; i < projectID.length; i++) {
    const char = projectID.charCodeAt(i);
    val = ((val << 5) - val) + char;
    val = val & val;
  }

  if (val < 0) {
    val = val * val;
  }

  return val;
}
