












import { computed, defineComponent, PropType } from "@vue/composition-api";
import { categoriesWithoutLevelName, allCategories } from "@/helpers/categories";

export default defineComponent({
  name: "ReadableCategories",
  props: {
    categories: {
      type: Array as PropType<string[]>,
      required: true,
    }
  },
  setup(props) {
    const level = computed(() => {
      const firstCatFullTitle = allCategories[props.categories[0]].title;
      const firstCatShortTitle = categoriesWithoutLevelName[props.categories[0]].title;
      return firstCatFullTitle.split(firstCatShortTitle)[1];
    });

    return {
      categoriesWithoutLevelName,
      level
    };
  },
});
