




















import {
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api";
import { getOnlineRoom } from "@/helpers/projectUtils";

export default defineComponent({
  name: "StartOnlineSessionButton",
  props: {
    projectId: {
      type: String as PropType<string>,
      required: true,
    },
    size: {
      type: String as PropType<string>,
      default: "small"
    }
  },
  setup(props) {
    const roomCode = ref(0);
    const URL = ref("");

    onMounted(() => {
      roomCode.value = getOnlineRoom(props.projectId);
      URL.value = `https://online.learnlink.no/${roomCode.value}`;
    });

    function launchOnline() {
      // this.$analytics.track('startOnlineSession', 'project', { projectID });
      const win = window.open(URL.value, "_blank");

      win?.focus();
    }

    return {
      launchOnline,
      URL,
    };
  },
});
